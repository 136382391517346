import { useAuthenticator } from "@aws-amplify/ui-react";
import logoInline from "../assets/logo-inline.png";
import logoInlineBlack from "../assets/logo-inline-black.png";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Sheet,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useColorScheme as useJoyColorScheme } from "@mui/joy/styles";
import { useColorScheme as useMaterialColorScheme } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/system";

export default function AppBar() {
  const { signOut } = useAuthenticator();
  const {
    mode,
    setMode: setMaterialMode,
    systemMode,
  } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const dark = mode === "dark" || systemMode === "dark";

  return (
    <Sheet
      sx={{
        height: 48,
        display: "flex",
        p: 1,
        alignItems: "center",
        justifyContent: "space-between",
        background: dark ? "#212121" : undefined,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <img
          src={
            mode === "dark" || systemMode === "dark"
              ? logoInline
              : logoInlineBlack
          }
          alt="Bind Studio logo"
          height={14}
        />
        {!sm ? (
          <Typography level="body-sm" sx={{ fontWeight: 600 }}>
            Customer Portal
          </Typography>
        ) : (
          []
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <ToggleButtonGroup
          value={mode}
          onChange={(_event, newValue) => {
            setMaterialMode(newValue);
            setJoyMode(newValue);
          }}
          sx={{
            mx: 0.5,
          }}
        >
          <Tooltip title={"Light"}>
            <IconButton color="neutral" size="sm" value={"light"}>
              <i className="fa-solid fa-sun" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"System"}>
            <IconButton color="neutral" size="sm" value={"system"}>
              <i className="fa-solid fa-circle-half-stroke" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Dark"}>
            <IconButton color="neutral" size="sm" value={"dark"}>
              <i className="fa-solid fa-moon" />
            </IconButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Chip
          color="neutral"
          variant="soft"
          size="sm"
          onClick={() =>
            window.open(
              "https://bindstudio.atlassian.net/servicedesk/customer/portal/3",
              "_blank"
            )
          }
        >
          Support
        </Chip>
        <Button
          size="sm"
          endDecorator={<i className="fa-solid fa-power-off"></i>}
          color="neutral"
          variant="soft"
          onClick={signOut}
        >
          Sign out
        </Button>
      </Box>
    </Sheet>
  );
}
