/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "customerportalapi",
            "endpoint": "https://u7sfyguu6j.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:a3adfa61-9d94-4947-8704-f1eefffca274",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5y7W9xLK8",
    "aws_user_pools_web_client_id": "1d5nhlrvvng06uk8ebc4crne5h",
    // "oauth": {},
    "oauth": {
        "domain": "accessbindstudio.auth.us-west-2.amazoncognito.com",
        "scope": ["aws.cognito.signin.user.admin", "email", "openid"],
        "redirectSignIn": "http://localhost:3000/,https://access.bind.studio/",
        "redirectSignOut": "http://localhost:3000/,https://access.bind.studio/",
        "responseType": "code",
      },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
