import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Divider, Sheet, Typography, Button } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import * as AmplifyLib from "aws-amplify";
import { getCurrentUser } from "aws-amplify/auth";
import * as AmplifyAuth from "aws-amplify/auth";
import { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import { Hub } from "aws-amplify/utils";
import awsconfig from "./aws-exports";
import Home from "./routes/home";
import AppBar from "./ui/AppBar";
AmplifyLib.Amplify.configure(awsconfig);

LicenseInfo.setLicenseKey(
  "2fe41b67b6ffd3f20b04b3b21b7682c6Tz03NTE3NCxFPTE3MjY5Mzc4NTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const getUser = async () => {
  try {
    const currentUser = await getCurrentUser();
  } catch (error) {
    console.error(error);
    console.log("Not signed in");
  }
};

Hub.listen("auth", ({ payload }) => {
  getUser();
});

function App() {
  const [user, setUser] = useState();
  const { mode, systemMode } = useColorScheme();

  useEffect(() => {
    getCurrentUser().then((u) => {
      console.log("getCurrentUser", u);
      if (u && Object.keys(u)?.length > 0) setUser(u);
    });
  }, []);

  const dark = mode === "dark" || systemMode === "dark";

  return (
    <Box>
      <Sheet>
        <Box
          sx={{
            height: "100dvh",
            width: "100dvw",
            display: "flex",
            flexDirection: "column",
            background: dark ? "#212121" : undefined,
          }}
        >
          <AppBar user={user} />
          <Divider />
          {user ? <Home user={user} /> : []}
        </Box>
      </Sheet>
    </Box>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    Header: () => (
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <img src={logo} alt="Bind Studio Logo" style={{ height: 90 }} />
      </Box>
    ),
    SignIn: () => <Box>Test</Box>,
    Footer: () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mt: 2,
          alignItems: "center",
        }}
      >
        <Button
          color="neutral"
          onClick={async () => {
            AmplifyAuth.signInWithRedirect({});
          }}
        >
          Signin with Jump Cloud
        </Button>
        <Typography sx={{ textAlign: "center", mt: 1 }} level="body-sm">
          © Copyright BIND Studio {new Date().getFullYear()}
        </Typography>
      </Box>
    ),
  },
});
