import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeCount from "../ui/TimeCount";

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

let fetching = false;

export default function Home({ user }) {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState();
  const { signOut } = useAuthenticator();

  useEffect(() => {
    refreshInstances();
    // debugger;
  }, [user]);

  const actionOnInstance = async (action, instanceID) => {
    setTimeout(refreshInstances, 30000);
    const restOperation = get({
      apiName: "customerportalapi",
      path: `/instances?action=${action}&instanceId=${instanceID}`,
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });

    try {
      const resp = await restOperation.response;
      const json = await resp.body.json();

      // updating status
      const index = instances.findIndex((i) => i.id === instanceID);

      let _instances = [...instances];
      if (json.StoppingInstances) {
        _instances[index].status = json.StoppingInstances[0].CurrentState.Name;
        _instances[index].stopping = true;
      }
      if (json.StartingInstances) {
        _instances[index].status = json.StartingInstances[0].CurrentState.Name;
        _instances[index].starting = true;
      }

      if (json.RebootingInstances) {
        _instances[index].status = json.RebootingInstances[0].CurrentState.Name;
        _instances[index].rebooting = true;
      }

      setInstances(_instances);
      refreshInstances();
    } catch (error) {
      return signOut();
    }
  };

  const refreshInstances = async () => {
    let idToken;
    if (fetching) return;
    fetching = true;
    try {
      const resp = (await fetchAuthSession()).tokens ?? {};
      idToken = resp.idToken;
    } catch (err) {
      return signOut();
    }

    const token = idToken.toString();
    setAuthToken(token);

    try {
      const restOperation = get({
        apiName: "customerportalapi",
        path: "/instances?action=list",
        options: {
          headers: {
            Authorization: token,
          },
        },
      });
      var username = user.signInDetails?.loginId ?? user?.username;

      if (username.includes("jumpcloud_")) {
        username = username.split("jumpcloud_")[1];
      } else if (!user.signInDetails) return signOut();

      if (!EMAIL_REGEXP.test(username)) return signOut();

      const response = await restOperation.response;
      const json = await response.body.json();
      const _instances = json.Reservations.reduce((a, v) => {
        v.Instances.forEach((i) => {
          if (
            i.Tags.find((t) => t.Key === "bind:user" && t.Value === username)
          ) {
            const name =
              i.Tags.find((t) => t.Key === "Name")?.Value ?? "Unamed";
            const protocol =
              i.Tags.find((t) => t.Key === "bind:remoteproto")?.Value ?? "";

            if (i.State.Name !== "terminated") {
              a.push({
                name,
                id: i.InstanceId ?? 0,
                type: i.InstanceType,
                state: i.State.Name,
                ipAddress: i.PublicIpAddress,
                launchTime: i.LaunchTime,
                starting: false,
                stopping: false,
                rebooting: false,
                protocol,
                tags: i.Tags,
              });
            }
          }
        });
        fetching = false;
        return a;
      }, []);
      setInstances(_instances);
      setLoading(false);
    } catch (error) {
      console.log("GET call failed: ", error);
      setLoading(false);
    }
  };

  const columns = [
    {
      headerName: "Actions",
      field: "actions",
      width: 290,
      renderCell: (p) => {
        const url = `${p.row.protocol}://${p.row.ipAddress}`;
        return (
          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "start", flex: 1 }}
          >
            {p.row.state === "stopped" ? (
              <Button
                startDecorator={<i className="fa-solid fa-play"></i>}
                variant="soft"
                size="sm"
                color="success"
                loading={p.row.starting}
                onClick={() => actionOnInstance("start", p.row.id)}
              >
                Start
              </Button>
            ) : (
              <Button
                startDecorator={<i className="fa-solid fa-stop"></i>}
                variant="soft"
                size="sm"
                color="danger"
                loading={p.row.stopping}
                onClick={() => actionOnInstance("stop", p.row.id)}
              >
                Stop
              </Button>
            )}
            {p.row.state !== "stopped" ? (
              <Button
                startDecorator={<i className="fa-solid fa-rotate-right"></i>}
                variant="soft"
                size="sm"
                color="neutral"
                loading={p.row.rebooting}
                onClick={() => actionOnInstance("reboot", p.row.id)}
              >
                Reboot
              </Button>
            ) : (
              []
            )}
            {p.row.state === "running" ? (
              <Tooltip title={url} arrow>
                <Button
                  size="sm"
                  variant="solid"
                  color="neutral"
                  startDecorator={<i className="fa-solid fa-plug"></i>}
                  onClick={() => window.open(url, "_blank")}
                >
                  Connect
                </Button>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        );
      },
    },
    {
      headerName: "Id",
      field: "id",
      width: 200,
    },
    {
      headerName: "Name",
      field: "name",
      width: 180,
    },
    {
      headerName: "Type",
      field: "type",
      width: 150,
    },
    {
      headerName: "State",
      field: "state",
      width: 120,
    },
    {
      headerName: "Last Launch Time",
      field: "launchTime",
      width: 200,
      valueFormatter: (r) => moment(r.value).format("lll"),
    },
    {
      headerName: "Running Time",
      field: "runningTime",
      flex: 1,
      renderCell: (r) =>
        r.row.state !== "stopped" ? (
          <TimeCount startTime={r.row.launchTime} />
        ) : (
          <Box />
        ),
    },
  ];

  return (
    <Box
      sx={{
        m: 2,
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        flexDirection: "column",
        gap: 1,
      }}
    >
      {loading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
          <Typography>Retrieving your instances...</Typography>
        </Box>
      ) : (
        <DataGridPro
          sx={{ width: "100%" }}
          autosizeOnMount
          autosizeOptions={{
            columns: ["name", "actions"],
            includeOutliers: true,
            includeHeaders: false,
          }}
          pagination
          getCellClassName={(p) => (p.field === "state" ? p.row.state : "")}
          rowSelection={false}
          slots={{
            toolbar: QuickSearchToolbar,
          }}
          slotProps={{
            toolbar: { onRefresh: refreshInstances },
          }}
          columns={columns}
          rows={instances}
        />
      )}
    </Box>
  );
}

function QuickSearchToolbar({ onRefresh }) {
  const [loading, setLoading] = useState(false);

  const handleRefresh = async () => {
    setLoading(true);
    await onRefresh();
    setLoading(false);
  };
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <Tooltip title="Refresh List" arrow>
        <IconButton onClick={handleRefresh} loading={loading}>
          <i className="fa-solid fa-arrows-rotate"></i>
        </IconButton>
      </Tooltip>
    </Box>
  );
}
