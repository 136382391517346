import { useEffect, useState } from "react";

export default function TimeCount({ startTime }) {
  const calculateTimeSince = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const difference = now - start; // difference in milliseconds

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return `${days ? `${days}d ` : ""} ${
      hours ? `${hours}h ` : ""
    } ${minutes}mn ${seconds}s`;
  };

  const [timeSince, setTimeSince] = useState(calculateTimeSince(startTime));

  useEffect(() => {
    const timerID = setInterval(() => {
      setTimeSince(calculateTimeSince(startTime));
    }, 1000); // Update every second

    return () => {
      clearInterval(timerID); // Clear interval on component unmount
    };
  }, [startTime]); // Dependency array, re-run effect if startTime changes

  return <span>{timeSince}</span>;
}
